<template>
	<div>
		<div class="header">
			<div class="main">
				<span class="txt">{{ this.form.typeLittle['模糊搜索'].keyword }} 关键字的搜索结果 <span
						style="color:#e94c53;">{{ total}}</span>条</span>
			</div>
		</div>
		<div class="body">
			<div class="for" v-for="item in list" :key="item.id">
				<div class="item" @click="CompanyHome(item)">
					<div class="img">{{item.name[0]}}</div>
					<div class="right">
						<span style="font-weight: bold; font-size: 20px; color: #222222;">{{item.name}}</span>
						<div class="info">
							<div>
								<label>法定代表人：</label><span>{{ item.oper_name }}</span>
							</div>
							<div>
								<label>统一社会信用代码：</label><span>{{ item.credit_no }}</span>
							</div>
							<div>
								<label>成立日期：</label><span>{{ item.start_date }}</span>
							</div>
							<div @click.stop>
								<label>联系方式：</label><span v-if="!item.phoneShow" @click.stop="checkNum(item)"
									style="color: #6ec1e8;cursor: pointer;">查看电话号码</span>
								<span v-else>{{item.phone}}
									<span @click="copy(item.phone)" style="color: #6ec1e8;cursor: pointer;"><img src="@/assets/images/fz.png"
											alt="" /> 复制</span>
								</span>

							</div>
							<!-- <div>
                                <label>地址：</label><span>{{ item.matchItems}}</span>
                            </div> -->
						</div>
					</div>
				</div>
				<div class="line"></div>
			</div>
		</div>
		<!-- 分页 -->
		<div class="pagination" v-if="show">
			<el-pagination background layout="prev, pager, next" :page-size="10" :current-page="pages"
				@current-change="changePage" :total="total">
			</el-pagination>
			<div class="total">共{{total}}条</div>
			<div class="jump">
				跳转到
				<input type="number" v-model.number="num">
				页
			</div>
			<el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px" @click="goShopPage">
				前往</el-button>
		</div>
		<div class="default" v-if="list.length  == 0">
			<img src="../../assets/images/square/xgsj.png" alt="">
			<span>未找到相关数据，请更换其他关键字</span>
		</div>

		<!-- 弹出框  查看联系方式-->
		<el-dialog title="" :visible.sync="dialogVisible" width="1200px" :before-close="handleDialogClose">
			<div class="dialog-title"><img src="../../assets/images/square/ts.png" alt=""
					style="margin-right: 5px;width: 25px; height: 25px;"> 正在查询<span
					style="color: #E40012;">{{ '正在查询xxxxx有限公司' }}</span>企业联系方式</div>
			<div style="width: 1140px; height: 0px; border: 1px solid #EEEEEE;"></div>
			<div class="dialog-body">
				<div style="display: flex; ">
					<label>已筛条件</label><span>联系方式</span>
				</div>
				<div style="margin-top: 30px;">
					<label for="">购买金额</label><span style="color: #E40012;">￥{{needMoney}} （已支付过的查询内容不会实际扣费）</span>
				</div>
				<div style="color: #00A71C; margin-top: 70px;">注：已跟你确认并绑定你的钱包</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" @click="confrimPay">确认支付</el-button>
				<el-button class="dialog-btn" style="border: 1px solid #E40012;background: #FFFFFF;color: #E40012;"
					type="primary" @click="dialogVisible = false">取消支付</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		name: 'searchResult',
		data() {
			return {
				dialogVisible: false,
				dialogVisible2: false,
				defaultShow: true,
				list: [],
				total: 0,
				num: '',
				pageNum: 1,
				needMoney: "",
				form: {
					typeBase: "BASE",
					typeLittle: {
						'模糊搜索': {
							keyword: "",
							skip: 0
						}
					},
					payPsd: sessionStorage.getItem("pwd")
				},
				pwd: sessionStorage.getItem("pwd"),
				info: null,
				pages: 0,
				show: true
			};
		},
		// watch: {
		//     '$route'() {
		//         this.allData()
		//     }
		// },
		mounted() {
			if (sessionStorage.getItem('list') != '') {
				this.list = JSON.parse(sessionStorage.getItem('list'))
				this.total = JSON.parse(sessionStorage.getItem('total'))
			} else {
				if (this.$route.query.page == undefined) {
					this.allData()
				} else {
					this.reloadAll()
					this.changePage(this.$route.query.page)
				}
			}
		},
		methods: {
			copy(item){
				let inputNode = document.createElement("input"); // 创建input
				inputNode.value = item; // 赋值给 input 值
				document.body.appendChild(inputNode); // 插入进去
				inputNode.select(); // 选择对象
				document.execCommand("Copy"); // 原生调用执行浏览器复制命令
				inputNode.className = "oInput";
				inputNode.style.display = "none"; // 隐藏
				this.$message.success('复制成功')
			},
			allData(num) {
				if (num == 1) {
					this.form.typeLittle['模糊搜索'].skip = 0
					this.num = ''
				}
				this.form.typeLittle['模糊搜索'].keyword = this.$route.query.key
				this.$util.postJ('/dataSquare/advanced/search', this.form, {
					type: true
				}).then(res => {
					console.log(res);
					res.data[0].val.data.items.forEach(item => {
						item.phoneShow = false
						item.phone = null
					})
					this.list = res.data[0].val.data.items
					this.total = res.data[0].val.data.total

				})
			},
			goShopPage() {
				if ((this.num - 1) * 10 > this.total) {
					this.num = ''
					return this.$message.error('分页跳过条数超过实际记录条数')
				}
				this.pages = this.num
				this.form.typeLittle['模糊搜索'].skip = (this.num - 1) * 10
				this.allData()
			},
			reloadAll() {
				this.show = false
				this.$nextTick(() => {
					this.show = true
				})
			},
			changePage(num) {

				this.pages = parseInt(num)
				this.form.typeLittle['模糊搜索'].skip = (num - 1) * 10
				this.allData()
			},
			checkNum(item) {
				this.info = item
				this.$util
					.post("/sys-param/list", {
						paramKey: "工商信息*企业联系方式",
					})
					.then((res) => {
						console.log(res);
						this.needMoney = res.data[0].paramValue;
						this.type = this.paramKey
					});
				console.log(item)
				this.dialogVisible = true
			},
			handleTagClose(tag) {
				this.tags.splice(this.tags.indexOf(tag), 1);
			},
			result() {
				// this.dialogVisible2 = true
			},
			back() {
				this.$router.back(-1)
			},
			CompanyHome(item) {
				this.$router.push({
					path: '/inquiryCompanyHome',
					query: {
						info: item,
						page: this.pages,
						key: this.$route.query.key
					}
				})
				sessionStorage.setItem('list', JSON.stringify(this.list))
				sessionStorage.setItem('total', JSON.stringify(this.total))
				let getAddressInfo = JSON.parse(sessionStorage.getItem('getAddressInfo1'))
				if (getAddressInfo.val.data.name != item.name) {
					sessionStorage.setItem('getAddressInfo1', '')
				}
			},
			handleDialogClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			handleDialogClose2(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			confrimPay() {
				let form = {
					typeBase: "工商信息",
					typeLittle: {
						"企业联系方式": {
							keyword: this.info.name
						}
					},
					payPsd: this.pwd
				}
				this.$util.postJ('/dataSquare/advanced/search', form).then(res => {
					console.log(res);
					if (res.code == 200) {
						this.info.phoneShow = true
						this.info.phone = res.data[0].val.data.telephone
						this.dialogVisible = false
					}

				})
				// 确认支付展示该公司联系方式
			},
			confrimPay2() {
				// 高级查询确认支付
			}
		},
		computed: {},
		watch: {},
		created() {

			if (this.$route.query.from == '搜索') this.tags = []

		}

	}
</script>

<style lang="scss" scoped>
	.default {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 500px;
		height: 520px;
		margin: auto;

		img {
			width: 47.45px;
			height: 56.94px;
		}

		span {
			margin-top: 22px;
			font-size: 20px;
			font-weight: 400;
			color: #222222;
		}
	}

	/deep/.el-dialog__body {
		padding: 0 20px;
	}

	/deep/ .el-tag {
		margin-right: 20px;
		margin-bottom: 10px;
	}

	.dialog-title {
		display: flex;
		align-items: center;
		font-size: 22px;
		font-weight: 400;
		color: #3d3d3d;
		margin-bottom: 10px;
	}

	.dialog-body {
		font-size: 16px;
		font-weight: 400;
		padding-top: 30px;
		padding-bottom: 64px;

		label {
			margin-right: 20px;
		}
	}

	.dialog-btn {
		width: 247px;
		height: 60px;
		background: #e40012;
		font-size: 18px;
		font-weight: 400;
		color: #ffffff;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-between;
		padding: 0 293px;
		margin-bottom: 80px;
	}

	.header {
		width: 100%;
		height: 60px;
		background: #f6f6f6;

		.main {
			display: flex;
			align-items: center;
			width: 1200px;
			height: 100%;
			margin: auto;

			.img {
				width: 16.76px;
				height: 16.76px;
			}

			.txt {
				font-size: 14px;
				font-weight: 400;
				color: #696969;
			}
		}
	}

	.for:last-child .line {
		display: none;
	}

	.body {
		width: 1200px;
		margin: auto;
		padding-top: 30px;

		.filter {
			border-bottom: 1px solid #f3f3f3;
		}

		.btn {
			width: 100%;
			display: flex;
			justify-content: right;
			margin: 20px 0;
		}

		.tag {
			display: flex;

			label {
				font-size: 16px;
				font-weight: 400;
				color: #222222;
				margin-right: 20px;
			}

			span {
				margin-right: 20px;
				margin-bottom: 20px;
			}
		}

		.item {
			display: flex;

			.img {
				width: 198px;
				height: 212px;
				background-color: #4480d8;
				text-align: center;
				line-height: 212px;
				font-size: 60px;
				color: #fff;
				// font-weight: bold;
				margin-right: 20px;
			}

			.right {
				height: 212px;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.info {
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;
				width: 982px;
				height: 166px;
				background: #f8f8f8;
				padding: 20px;
				font-size: 16px;
				font-weight: 400;
				color: #666666;

				div:nth-child(odd) {
					width: 60%;
				}

				div:nth-child(even) {
					width: 40%;
				}
			}
		}

		.line {
			width: 1200px;
			height: 0px;
			border: 1px solid #eeeeee;
			margin: 30px 0;
		}
	}

	.pagination {
		width: 1200px;
		margin: 100px auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/deep/.el-pager li {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		border: 1px solid #2ea7e0;
		margin: 0 3px;
		color: #2ea7e0 !important;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		color: #fff !important;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		// border: 1px solid #2ea7e0;
		background: transparent;
		margin: 0 3px;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 24px;
		color: #2ea7e0;
	}

	/deep/.el-pager li.active+li {
		border: 1px solid #2ea7e0;
	}

	.total {
		color: #2ea7e0;
		font-weight: bold;
		font-size: 16px;
	}

	.jump {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 0 20px 0 40px;
		color: #2ea7e0;

		input {
			width: 40px;
			height: 40px;
			border: 1px solid #0f6c97;
			font-size: 14px;
			color: #2ea7e0;
			outline: none;
			margin: 0 10px;
			border-radius: 4px;
			text-align: center;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
</style>
